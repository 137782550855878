//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Constants } from "../../models/Constants"
import { createNamespacedHelpers } from "vuex"

const { mapGetters } = createNamespacedHelpers("appointment")
export default {
  props: {
    showButtonsList: {
      default: false,
      type: Boolean,
    },
    enablePreloader: {
      default: true,
      type: Boolean,
    },
    showHeader: {
      default: false,
      type: Boolean,
    },
    markActiveType: {
      default: false,
      type: Boolean,
    },
    eventName: {
      default: "consultation-type",
      type: String,
    },
    availableConsultationTypes: {
      type: Array,
      default: function () {
        return [
          Constants.Appointment.CONSULTATION_CALL_OPINION,
          Constants.Appointment.CONSULTATION_OPINION,
        ]
      },
    },
    allConsultationTypes: {
      type: Array,
      default: function () {
        return [
          {
            type: Constants.Appointment.CONSULTATION_CALL_OPINION,
            icon: "fa-video-camera",
            label: "appointment.video-call",
          },
          {
            type: Constants.Appointment.CONSULTATION_OPINION,
            icon: "fa-envelope-open",
            label: "appointment.second-opinion-only",
          },
          {
            type: Constants.Appointment.CONSULTATION_DIAGNOSTIC_TEST,
            icon: "fa-envelope-open",
            label: "appointment.diagnostic-test",
          },
          {
            type: Constants.Appointment.CONSULTATION_OVERSEAS_TREATMENT,
            icon: "fa-envelope-open",
            label: "appointment.overseas-treatment",
          },
          {
            type: Constants.Appointment.CONSULTATION_FRACTURE_COVER,
            icon: "fa-envelope-open",
            label: "appointment.fracture-cover",
          },
        ]
      },
    },
  },
  data() {
    return {
      processing: false,
      constants: Constants.Appointment,
    }
  },
  computed: {
    appointment() {
      return this.$store.state.appointment
    },
    consultationTypes() {
      return this.allConsultationTypes.filter(
        (consultationType) => this.availableConsultationTypes.indexOf(consultationType.type) !== -1
      )
    },
  },
  methods: {
    setType(type) {
      this.processing = true
      this.$emit("is-processing", true)
      this.$store
        .dispatch("appointment/setConsultationType", {
          appointment_id: this.appointment.appointment_id,
          type: type,
          availableConsultationTypes: this.availableConsultationTypes,
        })
        .then((response) => {
          this.processing = false
          this.$bridge.emit("addToast", response.data.message, "success")
          this.$emit("is-processing", false)
          this.$emit(this.eventName, {
            result: true,
            response: response,
            type: type,
            availableConsultationTypes: this.availableConsultationTypes,
          })
        })
        .catch((error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
          this.$emit("is-processing", false)
          this.$emit(this.eventName, { result: false, response: response, type: type })
        })
    },
  },
}
