//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Customize from "../../../plugins/Customize.js"
import Modal from "../../../plugins/modal/modal.js"
export default {
  data() {
    return {
      processing: true,
    }
  },
  inject: ["context", "permissions"],
  mixins: [Customize()],
  props: {
    sectionText: {
      default: function () {
        return this.$t.get("appointment.video-call")
      },
    },
  },
  computed: {
    permissionStartCall() {
      return this.context.participants
        .find((participant) => participant.user_id === this.$store.state.user.user_id)
        .permissions.find(
          (permission) => permission === "group_global_enquiry_start_call_appointment"
        )
    },
    appointment() {
      return this.$store.state.appointment
    },
    user() {
      return this.$store.state.user
    },
  },
  methods: {
    startCall() {
      this.processing = true
      this.$http
        .get(`/ajax/appointment/${this.appointment.appointment_id}/call-configuration`)
        .then((response) => {
          this.processing = false
          let isRecordingAvailable = response.data.recording.available ?? false

          Modal.create(this)
            .title(this.$t.get("common.action-required"))
            .component(() => import("./../StartTheCallModal.vue"))
            .classes(["modal-start-the-call"])
            .props({
              isRecordingAvailable: isRecordingAvailable,
            })
            .events({
              startTheCall: (intentionToEnableRecording) => {
                this.$store.commit("appointment/suppressStartPrompt")
                return this.$http
                  .post(`/ajax/appointment/${this.appointment.appointment_id}/call/start`, {
                    enable_recording: intentionToEnableRecording,
                  })
                  .then(
                    (response) => {
                      this.$bridge.emit(
                        "addToast",
                        this.$t.get("appointment.redirecting-to-the-room"),
                        "success"
                      )
                      window.location.href =
                        "/appointment/" + this.appointment.appointment_id + "/call"
                      return true
                    },
                    (response) => {
                      this.$bridge.emit("addToast", response.data.message, "error")
                      return false
                    }
                  )
              },
              cancelCall: () => {
                return
              },
            })
            .show()
        })
        .catch((error) => {
          this.processing = false
          this.$bridge.emit("addToast", error.response.data.message, "error")
        })
    },
  },
}
