var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return typeof _vm.context !== typeof undefined
    ? _c("div", [
        _c("div", { staticClass: "request-approval" }, [
          _c("div", { staticClass: "section" }, [
            _vm._v(_vm._s(_vm.sectionText)),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "styled",
                  rawName: "v-styled:color",
                  value: _vm.palette.colors.primary.alt,
                  expression: "palette.colors.primary.alt",
                  arg: "color",
                },
                {
                  name: "styled",
                  rawName: "v-styled:background",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "background",
                },
                {
                  name: "styled",
                  rawName: "v-styled:border-color",
                  value: _vm.palette.colors.primary.main,
                  expression: "palette.colors.primary.main",
                  arg: "border-color",
                },
                {
                  name: "styled",
                  rawName: "v-styled:background.hover",
                  value: _vm.palette.colors.hover.alt,
                  expression: "palette.colors.hover.alt",
                  arg: "background",
                  modifiers: { hover: true },
                },
                {
                  name: "styled",
                  rawName: "v-styled:color.hover",
                  value: _vm.palette.colors.hover.main,
                  expression: "palette.colors.hover.main",
                  arg: "color",
                  modifiers: { hover: true },
                },
              ],
              staticClass: "button accent",
              attrs: { disabled: !_vm.permissionStartCall },
              on: { click: _vm.startCall },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t.get("appointment.start-the-call")) +
                  "\n    "
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }