import { render, staticRenderFns } from "./HeaderNotifications.vue?vue&type=template&id=6c2a0532&"
import script from "./HeaderNotifications.vue?vue&type=script&lang=js&"
export * from "./HeaderNotifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4259483955/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c2a0532')) {
      api.createRecord('6c2a0532', component.options)
    } else {
      api.reload('6c2a0532', component.options)
    }
    module.hot.accept("./HeaderNotifications.vue?vue&type=template&id=6c2a0532&", function () {
      api.rerender('6c2a0532', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/header/HeaderNotifications.vue"
export default component.exports